import { Footer, Navbar } from "components/newHome";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { About, Explore, GetStarted, Hero, WhatsNew } from "sections";

const Home = () => {
  const router = useRouter();

  useEffect(() => {
    const isPWA =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone === true ||
      document.referrer.includes("android-app://") ||
      document.referrer.includes("ios-app://");

    if (isPWA) {
      router.push("/login", "/login", { locale: false });
    }
  }, [router]);

  return (
    <div className="bg-white " dir="ltr">
      <Navbar />
      <Hero />
      <div className="relative">
        <About />
        <div className="gradient-03 z-0" />
        <Explore />
      </div>
      <div className="relative">
        <GetStarted />
        <div className="gradient-04 z-0" />
        <WhatsNew />
      </div>
      {/* <div className="relative">
      <div className="gradient-04 z-0" />
    </div> */}
      <Footer />
    </div>
  );
};

export default Home;
