"use client";

import { motion } from "framer-motion";

import { NewFeatures, TitleText, TypingText } from "components/newHome";
import { useTranslation } from "react-i18next";
import styles from "styles";
import { fadeIn, planetVariants, staggerContainer } from "utils/motion";

function WhatsNew() {
  const { t } = useTranslation("home");

  return (
    <section className={`${styles.paddings} relative z-10`} id="whatsnew">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8 overflow-hidden`}
      >
        <motion.div
          variants={fadeIn("right", "tween", 0.2, 1)}
          className="flex-[0.95] flex justify-center flex-col"
        >
          <TypingText title={t("whatsnew")} />
          <TitleText title={<>{t("whatsnewindh")}</>} />
          <div className="mt-[48px] flex flex-wrap justify-between gap-[24px]">
            <NewFeatures
              imgUrl="/vrpano.svg"
              title={t("title1")}
              subtitle={t("subtitle1")}
            />
            <NewFeatures
              imgUrl="/head.svg"
              title={t("title2")}
              subtitle={t("subtitle2")}
            />
          </div>
        </motion.div>

        <motion.div
          variants={planetVariants("right")}
          className={`flex-1 ${styles.flexCenter}`}
        >
          <img
            src="/whats-new.png"
            alt="WhatsNew"
            className="w-[90%] h-[90%] object-contain"
          />
        </motion.div>
      </motion.div>
    </section>
  );
}

export default WhatsNew;
