"use client";

import { motion } from "framer-motion";

import { StartSteps, TitleText, TypingText } from "components/newHome";
import styles from "styles";
import { fadeIn, planetVariants, staggerContainer } from "utils/motion";
import { useTranslation } from "react-i18next";

function GetStarted() {
  const { t } = useTranslation("home");

  return (
    <section
      className={`${styles.paddings} relative z-10 overflow-hidden`}
      id="getstarted"
    >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
      >
        <motion.div
          variants={planetVariants("left")}
          className={`flex-1 ${styles.flexCenter}`}
        >
          <img
            src="/get-started.png"
            alt="get-started"
            className="w-[90%] h-[90%] object-contain"
          />
        </motion.div>
        <motion.div
          variants={fadeIn("left", "tween", 0.2, 1)}
          className="flex-[0.75] flex justify-center flex-col"
        >
          <TypingText title={t("howitworks")} />
          <TitleText title={t("getstartedinfew")} />
          <div className="mt-[31px] flex flex-col max-w-[370px] gap-[24px]">
            <StartSteps number="1" text={t("firststep")} />
            <StartSteps number="2" text={t("secondstep")} />
            <StartSteps number="3" text={t("thirdstep")} />
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
}

export default GetStarted;
