"use client";

import { fadeIn } from "@/utils/motion";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styles from "styles";

const ExploreCard = ({ id, imgUrl, title, index, active, handleClick }) => {
  const { t } = useTranslation("home");

  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      initial="hidden"
      animate="show"
      className={`relative ${
        active === id ? "lg:flex-[3.5] flex-[10]" : "lg:flex-[0.5] flex-[2]"
      } flex items-center justify-center min-w-[170px] lg:h-[700px] h-[400px] transition-[flex] duration-700 ease-out-flex cursor-pointer`}
      onClick={() => handleClick(id)}
    >
      <img
        src={imgUrl}
        alt={title}
        className="absolute w-full h-full object-cover rounded-[24px] transition-transform duration-700"
      />
      {active !== id ? (
        <motion.h3
          className="font-semibold sm:text-[26px] text-[18px] text-white absolute z-0 lg:bottom-20 lg:rotate-[-90deg] lg:origin-[0,0] transition-opacity duration-700"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {title}
        </motion.h3>
      ) : (
        <motion.div
          className="absolute bottom-0 p-8 flex justify-start w-full flex-col bg-[rgba(0,0,0,0.5)] rounded-b-[24px] transition-opacity duration-700"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div
            className={`${styles.flexCenter} w-[60px] h-[60px] rounded-[24px] glassmorphism mb-[16px]`}
          >
            <img
              src="/head.svg"
              alt="head"
              className="w-1/2 h-1/2 object-contain"
            />
          </div>
          <p className="font-normal text-[16px] leading-[20.16px] text-white uppercase">
            {t("explore_our_collection")}
          </p>
          <h2 className="mt-[24px] font-semibold sm:text-[32px] text-[24px] text-white">
            {title}
          </h2>
        </motion.div>
      )}
    </motion.div>
  );
};

export default ExploreCard;
